<template>
    <div class="tab-panel" v-show="activeTab == 'details'">
        <div
            class="informations-bloc no-mobile"
            :class="{
                greyType: isBasic,
                orangeType: isSimplified,
                blueType: isComplex,
            }"
            @click="editAction()"
        >
            <Infos
                :address="address"
                :name="name"
                :comment="comment"
                :type="type"
            />
        </div>
        <ClassicBreadCrumb :title="name" v-show="false" v-if="name" />

        <!-- ACTIONS -->
        <div class="actions">
            <!-- create -->
            <basic-button
                class="action create"
                color="orange"
                @click="createAction()"
                v-if="
                    checkRight('G_APP') ||
                    checkRight('G_KEY') ||
                    checkRight('G_KEY_MOBIL')
                "
            >
                <i class="fas fa-plus"></i>
                <span class="reduce">
                    {{ $t("entityDetails.actions.create") }}
                </span>
            </basic-button>
            <!-- import -->
            <span v-if="!isBasic">
                <basic-button
                    class="action import"
                    color="blue"
                    @click="importAction()"
                    v-if="checkRight('G_RESIDENCES')"
                >
                    <i class="far fa-arrow-alt-circle-up"></i>
                    <span class="reduce">
                        {{ $t("entityDetails.actions.import") }}
                    </span>
                </basic-button>
            </span>
            <!-- export -->
            <span>
                <div class="export-wrapper">
                    <basic-button
                        class="action export"
                        :class="{
                            'dropdown-toggle': checkPrivilege('LAST_KEY'),
                        }"
                        color="blue"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        v-if="checkRight('G_RESIDENCES')"
                        @click="
                            checkPrivilege('LAST_KEY')
                                ? null
                                : goToV1View(
                                      'print/residence/index/id=' + id,
                                      true
                                  )
                        "
                    >
                        <i class="far fa-arrow-alt-circle-down"></i>
                        <span class="reduce">
                            {{ $t("entityDetails.actions.export") }}
                        </span>
                    </basic-button>
                    <ul
                        class="dropdown-menu dropdown-export"
                        aria-labelledby="export"
                        v-if="checkPrivilege('LAST_KEY')"
                    >
                        <a
                            @click="
                                goToV1View(
                                    'print/residence/index/id=' + id,
                                    true
                                )
                            "
                            class="dropdown-item"
                        >
                            <i class="fas fa-list"></i>
                            {{ $t("entityDetails.actions.stairwellExportC") }}
                        </a>
                        <a
                            v-if="checkPrivilege('LAST_KEY')"
                            class="dropdown-item"
                            @click="
                                $router.push({
                                    name: 'ExportFobsResidence',
                                })
                            "
                        >
                            <i class="fas fa-list"></i>
                            {{ $t("exportFobs.fobsTitle") }}
                        </a>
                    </ul>
                </div>
            </span>
            <!-- support -->
            <span>
                <basic-button
                    class="action support"
                    color="blue"
                    @click="
                        goToV1View('data/residence/support/load/idres=' + id)
                    "
                    v-if="country === '1'"
                >
                    <i class="fas fa-users-cog"></i>
                    <span class="reduce">
                        {{ $t("entityDetails.actions.support") }}
                    </span>
                </basic-button>
            </span>
        </div>

        <!-- ACCESSES -->
        <div class="accesses-materials">
            <div class="middle-block">
                <h1 class="panel-title">
                    {{ $t("entityDetails.internalAccesses") }}
                </h1>
                <DisplayModeAccessCard />
            </div>

            <!-- Display List Columns -->
            <div class="accesses-list-grid-title" v-if="displayMode === 'list'">
                <div></div>
                <div>{{ $t("residencesList.arrayColumns.name") }}</div>
                <div>{{ $t("centralUnitsList.arrayColumns.option") }}</div>
            </div>
            <div
                :class="'accesses' + '-' + displayMode"
                v-if="accesses.length > 0"
            >
                <access-card
                    v-if="this.nb_connectit > 0"
                    :isConnectit="true"
                    :display="displayMode"
                >
                </access-card>
                <access-card
                    v-for="item in accesses"
                    :key="item.id"
                    :access="item"
                    :entityParent="entity"
                    :idParent="id"
                    :display="displayMode"
                >
                </access-card>
            </div>
            <div :class="'accesses' + '-' + displayMode" v-else>
                <access-card
                    :isCreator="true"
                    :entityParent="entity"
                    :display="displayMode"
                >
                </access-card>
                <access-card
                    v-if="this.nb_connectit > 0"
                    :isConnectit="true"
                    :display="displayMode"
                >
                </access-card>
            </div>
            <div
                class="accesses-show-button"
                v-if="accesses.length < countAccess"
            >
                <button @click="showMore">
                    <span>
                        {{ $t("button.seeAll") }}
                        <i class="fas fa-chevron-circle-down"></i>
                    </span>
                </button>
            </div>
        </div>

        <!-- STAIRWELLS -->
        <div class="stairwells" v-if="stairwells.length > 0">
            <stairwell-card
                v-for="item in sortedStairwells"
                :key="item.id"
                :stairwell="item"
                :entityParent="entity"
                :entityParentId="id"
            ></stairwell-card>
        </div>
        <div class="stairwells" v-else-if="isComplex">
            <stairwell-card
                :isCreator="true"
                :entityParent="entity"
                :entityParentId="id"
            ></stairwell-card>
        </div>

        <!-- APARTMENTS -->
        <div class="apartments" v-if="isSimplified">
            <apartments-list :apartments="apartments"></apartments-list>
        </div>

        <!-- KEYS -->
        <div class="keys-wrapper">
            <h1 class="panel-title keys-title" v-if="isBasic">
                {{ $t("entityDetails.accessories", { count: keys.length }) }}
            </h1>
            <div>
                <DisplayModeKeyCard v-if="isBasic" />
            </div>
        </div>
        <div class="keys" v-if="keys.length > 0">
            <div :class="displayKeys">
                <div class="key-list-title" v-if="displayKeys == 'list'">
                    <div></div>
                    <div>{{ $t("centralUnitsList.arrayColumns.type") }}</div>
                    <div>{{ $t("centralUnitsList.arrayColumns.serial") }}</div>
                    <div>{{ $t("bloc_erp.color") }}</div>
                    <div>{{ $t("agencesList.arrayColumns.comment") }}</div>
                    <div>{{ $t("centralUnitsList.arrayColumns.option") }}</div>
                </div>
                <key-card
                    v-for="item in keys"
                    v-on:fob-deleted="refreshFobs()"
                    :key="item.id"
                    :keyObject="item"
                    :display="displayKeys"
                >
                </key-card>
            </div>
            <pagination
                :page="currentPageKeys"
                :pages-count="pageKeysCount"
                v-on:new-current-page="changePage($event)"
            />
        </div>
        <div class="keys" v-else-if="isBasic">
            <key-card :isCreator="true" :hideResidentCreation="true"></key-card>
        </div>
    </div>
</template>

<script>
import { entityType, residenceType, stairwellType } from "@/enums"
import v1mixin from "@/mixins/v1"
import Pagination from "@/components/basic/Pagination"
import BasicButton from "@/components/basic/BasicButton.vue"
import AccessCard from "@/components/entities/AccessCard"
import StairwellCard from "@/components/entities/StairwellCard"
import ApartmentsList from "@/components/entities/ApartmentsList"
import KeyCard from "@/components/entities/KeyCard"
import Infos from "@/views/residence/Infos"
import DisplayModeAccessCard from "../../components/DisplayModeAccessCard.vue"
import DisplayModeKeyCard from "@/components/DisplayModeKeyCard"
import ClassicBreadCrumb from "@/components/entities/ClassicBreadCrumb.vue"
import { mapState } from "vuex"

export default {
    name: "Home",
    props: [
        "activeTab",
        "type",
        "address",
        "accesses",
        "nb_connectit",
        "comment",
        "id",
        "apartments",
        "keys",
        "countAccess",
        "stairwells",
        "name",
        "currentPageKeys",
        "pageKeysCount",
        "keysCount",
    ],
    components: {
        Pagination,
        Infos,
        BasicButton,
        AccessCard,
        StairwellCard,
        ApartmentsList,
        KeyCard,
        DisplayModeAccessCard,
        DisplayModeKeyCard,
        ClassicBreadCrumb,
    },
    mixins: [v1mixin],
    data() {
        return {
            country: this.$store.getters["account/getCountry"],
            entity: entityType.RESIDENCE,
        }
    },
    computed: {
        ...mapState({
            displayMode: (state) => state.account.displayAccessCard,
            displayKeys: (state) => state.account.displayKeyCard,
        }),
        isBasic() {
            return this.type === residenceType.BASIC
        },
        isSimplified() {
            return this.type === residenceType.SIMPLIFIED
        },
        isComplex() {
            return this.type === residenceType.COMPLEX
        },
        sortedStairwells() {
            let stairwells = this.stairwells
            return stairwells.sort((a, b) => {
                if (
                    (a.type === stairwellType.STAIRWELL &&
                        b.type === stairwellType.BUILDING) ||
                    (a.type === b.type &&
                        a.nom.toLowerCase() > b.nom.toLowerCase())
                ) {
                    return 1
                } else if (
                    (a.type === stairwellType.BUILDING &&
                        b.type === stairwellType.STAIRWELL) ||
                    (a.type === b.type &&
                        a.nom.toLowerCase() < b.nom.toLowerCase())
                ) {
                    return -1
                } else {
                    return 0
                }
            })
        },
    },
    methods: {
        refreshFobs() {
            this.$emit("refreshFobs")
        },
        editAction() {
            this.$emit("editAction")
        },
        showMore() {
            this.$emit("showMore")
        },
        changePage(value) {
            this.$emit("changePage", value)
        },
        createAction() {
            this.openV1Modal("data/residence/add", "Act_Zone")
        },
        importAction() {
            if (this.isSimplified) {
                this.goToV1View("data/import_param/type=1&resid=" + this.id)
            } else {
                this.goToV1View(
                    "data/import_param/res=1&type=1&resid=" + this.id
                )
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/details.scss";

@media all and (min-width: 1024px) {
    .no-mobile {
        display: none;
    }
}

@media all and (max-width: 1024px) {
    .mobile {
        display: none;
    }
}

.actions {
    display: flex;
    justify-content: center;
    gap: 20px;
    button {
        margin: 0 !important;
    }

    .export-wrapper {
        position: relative;
        max-width: min-content;

        .dropdown-export {
            position: absolute;
            margin: 0 !important;
            right: 0;
            width: max-content !important;
        }
    }
}

.keys {
    display: flex;
    flex-direction: column;
    gap: 0px !important;

    .key-list-title {
        width: 100%;
        display: grid !important;
        grid-template-columns: 1fr 2fr 2fr 1fr 3fr 1fr;
        font-size: $normal;
        border-bottom: 1px solid $grey-light;
        padding: 0.5em 0;

        div {
            font-family: $font_avenir_heavy;
            color: $grey-darker;
        }

        div:nth-child(6) {
            display: flex;
            justify-content: end;
        }
    }

    .card {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 40px;
        border: none;
        // background-color: red;
    }
}

.middle-block {
    display: flex;
    align-items: center;
    margin-bottom: 2em;

    h1 {
        margin: 0;
    }

    div {
        display: flex;

        gap: 1em;
        height: max-content;

        span {
            color: $grey-darker;
            border-radius: 4px;
            transition: all 0.3s ease-in-out;
            display: flex;
            align-items: center;
            padding: 0 1em;
            font-family: $font_avenir_heavy;
        }

        i {
            color: $grey-darker;
            cursor: pointer;
            padding: 8px;
            border-radius: 4px;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: $grey-neutral;
                color: $orange-light;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}

.keys-wrapper {
    display: flex;
    justify-content: space-between;

    div {
        display: flex;
        align-items: center;
    }
}

.accesses-card {
    display: grid !important;
    @media all and (max-width: 1280px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media all and (min-width: 1280px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media all and (max-width: 720px) {
        grid-template-columns: repeat(1, 1fr);
    }
    width: 100%;
    gap: 2em;
    margin: 0;
}

.accesses-list-grid-title {
    width: 100%;
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 10fr 1fr;
    align-items: center;
    border-bottom: 1px solid $grey-light;
    padding: 1em 0;

    div {
        font-family: $font_avenir_heavy;
        color: $grey-darker;
    }

    div:nth-child(1) {
        max-width: 150px;
        min-width: 100px;
    }

    div:nth-child(3) {
        justify-content: end;
        display: flex;
    }
}
.accesses-list {
    display: flex;
    flex-direction: column;

    div {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-radius: 0px;

        div {
            display: grid;
            grid-template-columns: 1fr, 1fr 1fr;
        }
    }
}
</style>
