<template>
    <div v-show="!isLoading" class="panels">
        <LeftMenu
            :type="type"
            :address="getAddress"
            :activeTab="activeTab"
            :ipass="ipass"
            :manage="manage"
            :name="name"
            :comment="comment"
            @editAction="editAction"
            @loadSubaccounts="loadSubaccounts"
        />

        <div class="content-panel">
            <v3-warning
                v-if="activeTab === 'authorizations' || activeTab === 'codes'"
            />
            <!-- DETAILS -->
            <Home
                :activeTab="activeTab"
                :type="type"
                :address="getAddress"
                :accesses="accesses"
                :nb_connectit="nb_connectit"
                :id="id"
                :apartments="apartments"
                :keys="keys"
                :countAccess="countAccess"
                :stairwells="stairwells"
                :name="name"
                :comment="comment"
                :currentPageKeys="currentPageKeys"
                :pageKeysCount="pageKeysCount"
                :keysCount="keysPerPage"
                @editAction="editAction"
                @showMore="showMore"
                @refreshFobs="refreshFobs"
                @changePage="changePageKeys"
            />

            <!-- AUTHORIZATIONS -->
            <div
                id="v1-auths-bloc"
                class="tab-panel"
                v-show="activeTab === 'authorizations'"
            >
                <loader class="v1-tab-loader"></loader>
            </div>

            <!-- CODES -->
            <div class="tab-panel" v-if="activeTab === 'codes'">
                <CodeByAccessView :residenceId="id" residencePart="Residence" />
            </div>

            <!-- CENTRAL UNITS -->
            <div class="tab-panel" v-show="activeTab === 'central-units'">
                <central-units-list :idResidence="centralUnitsParam">
                </central-units-list>
            </div>

            <!-- SUBACCOUNTS -->
            <div class="tab-panel" v-show="activeTab === 'subaccounts'">
                <subaccounts-list
                    :subaccounts="subaccounts"
                    :agences="agences"
                ></subaccounts-list>
            </div>
        </div>
    </div>
    <div v-show="isLoading" class="panels-loading">
        <loader></loader>
    </div>
</template>

<script>
import httpIntratone from "@/services/httpIntratone.js"

import {
    getResidenceSubaccountsById,
    getResidenceSummaryById,
} from "@/services/intratone/residence.js"

import LeftMenu from "@/views/residence/LeftMenu"
import SubaccountsList from "@/components/entities/SubaccountsList.vue"
import CentralUnitsList from "@/components/entities/CentralUnitsList.vue"
import Loader from "@/components/basic/Loader.vue"

import v1mixin from "@/mixins/v1.js"
import { residenceType } from "@/enums"
import { entityType } from "@/enums"
import { stairwellType } from "@/enums"

import { useToast } from "vue-toastification"
import { getAccess } from "@/services/intratone/access"
import V3Warning from "@/components/V3Warning"

import Home from "@/views/residence/Home"
import CodeByAccessView from "../codeByAccess/CodeByAccessView.vue"
import { getFobs } from "@/services/intratone/fob"

const toast = useToast()

export default {
    name: "Residence",
    components: {
        Home,
        LeftMenu,
        V3Warning,
        SubaccountsList,
        CentralUnitsList,
        Loader,
        CodeByAccessView,
    },
    mixins: [v1mixin],
    data() {
        return {
            isLoading: true,
            // Entity type and data
            entity: entityType.RESIDENCE,
            id: this.$route.params.id,
            name: null,
            address: null,
            comment: null,
            type: null,
            manage: null,
            ipass: null,
            // Entity path
            path: null,
            // Accesses
            accesses: [],
            // Connectit
            nb_connectit: 0,
            // Stairwells
            stairwells: [],
            // Apartments
            apartments: [],
            // Keys
            keys: [],
            // Residents
            residents: [],
            // Subaccounts
            subaccounts: [],
            // Agences
            agences: [],
            // Central units
            centralUnits: [],
            // Active tab
            activeTab: "details",
            // Show all accesses
            showAllAccesses: false,
            // Types of entity
            entityType,
            // Types of residences
            residenceType,
            // Types of stairwells
            stairwellType,
            // Value for central units list prop
            centralUnitsParam: null,
            // Central units query filter
            centralUnitsFilters: null,
            // Authorizations tab has been displayed
            authorizationsDisplayed: false,
            // Codes tab has been displayed
            codesDisplayed: false,
            accessPage: 1,
            accessTotalPage: 1,
            countAccess: 0,
            currentPageKeys: 1,
            pageKeysCount: 1,
            keysPerPage: 102,
        }
    },
    computed: {
        getAddress() {
            let address = this.address
            if (address !== "" && address !== null) {
                if (address.length > 50) {
                    address = address.substring(0, 50) + "..."
                }
            } else {
                address = "--"
            }
            return address
        },
        idParameter() {
            return this.$route.params.id
        },
        tabParameter() {
            return this.$route.params.tab !== ""
                ? this.$route.params.tab
                : "details"
        },
    },
    methods: {
        showMore() {
            getAccess({
                idres: this.id,
                limit: this.countAccess,
                idcag: 0,
                for: "linked",
                stat: "cles",
            }).then((response) => {
                response.data.list.forEach((newAccess) => {
                    if (
                        this.accesses.find(
                            (exist) => exist.id === newAccess.id
                        ) === undefined
                    ) {
                        this.accesses.push(newAccess)
                    }
                })
                this.accessPage = parseInt(response.data._page)
                this.accessTotalPage = parseInt(response.data._pages)
            })
        },
        displayTab(tabLabel) {
            this.activeTab = tabLabel
            if (tabLabel === "central-units") {
                this.centralUnitsParam = this.$route.params.id
            }
            if (
                tabLabel === "authorizations" &&
                this.authorizationsDisplayed === false
            ) {
                this.openV1Modal(
                    "data/autorisation/autos/hide_bar=1&res=" + this.id,
                    "v1-auths-bloc"
                )
                this.authorizationsDisplayed = true
            }
            if (tabLabel === "codes" && this.codesDisplayed === false) {
                this.codesDisplayed = true
            }
        },
        editAction() {
            // this.goToV1View('data/residence/load/edit=1&id=' + this.id)
            this.$router.push({
                name: "EditResidence",
                params: {
                    id: this.id,
                },
            })
        },
        async load() {
            // Resetting values
            this.isLoading = true
            this.id = this.$route.params.id
            this.name = null
            this.comment = null
            this.path = null
            this.address = null
            this.type = null
            this.accesses = []
            this.stairwells = []
            this.apartments = []
            this.keys = []
            this.residents = []
            this.subaccounts = []
            this.agences = []
            this.centralUnits = []

            // V1 site request in order to set v1 site session with residence
            const residenceDetail = await this.loadResidence()
            this.isLoading = false

            // Displaying tab, 'details' by default
            if (this.tabParameter === "" || this.tabParameter === undefined) {
                this.displayTab("details")
            } else {
                this.displayTab(this.tabParameter)
            }
        },
        async refreshFobs() {
            try {
                const params = {
                    idres: this.id,
                    page: this.currentPageKeys,
                    limit: this.keysPerPage,
                }
                let fobsListResponse = await getFobs(params)
                if (fobsListResponse === null) {
                    throw new Error()
                }
                if (fobsListResponse.state === "error") {
                    if (fobsListResponse.code === "API_RESTRICTED") {
                        this.$router.push({
                            path: "/error/401",
                        })
                    } else {
                        this.$router.push({
                            path: "/error/404",
                        })
                    }
                    return
                }
                this.keys = fobsListResponse.data.list
            } catch (error) {
                console.log(error)
            }
        },
        async loadSubaccounts() {
            try {
                let subaccountsResponse = await getResidenceSubaccountsById(
                    this.$route.params.id
                )
                if (subaccountsResponse === null) {
                    throw new Error()
                }
                this.subaccounts = subaccountsResponse.data.subaccounts
                this.agences = subaccountsResponse.data.agences
            } catch (error) {
                console.log(error)
            }
        },
        changePageKeys(value) {
            this.currentPageKeys = value
            this.refreshFobs()
        },
        async loadResidence() {
            try {
                const data = {
                    limit_keys: this.keysPerPage,
                    page_keys: this.currentPageKeys,
                }
                // Getting residence
                let residenceResponse = await getResidenceSummaryById(
                    this.$route.params.id,
                    data
                )
                if (residenceResponse === null) {
                    throw new Error()
                }
                if (residenceResponse.state === "error") {
                    if (residenceResponse.code === "API_RESTRICTED") {
                        this.$router.push({
                            path: "/error/401",
                        })
                    } else {
                        this.$router.push({
                            path: "/error/404",
                        })
                    }
                    return
                }
                // Residence data
                if (residenceResponse.data) {
                    this.id = residenceResponse.data.residence.id
                    this.name = residenceResponse.data.residence.nom
                    this.$store.dispatch("residences/set_currentResidenceName", this.name)

                    this.address = residenceResponse.data.residence.adresse
                    this.comment = residenceResponse.data.residence.comments
                    this.path = residenceResponse.data.path
                    this.type = parseInt(
                        residenceResponse.data.residence.simple,
                        10
                    )
                    this.manage = residenceResponse.data.residence.loc === "1"
                    this.ipass = residenceResponse.data.residence.robot === "1"
                    this.centralUnitsParam = this.$route.params.id
                    this.$store.commit(
                        "account/setResidenceId",
                        parseInt(this.id)
                    )

                    this.$store.commit(
                        "account/setResidenceManage",
                        this.manage
                    )
                    this.$store.commit("account/setResidenceType", this.type)
                    let accessToShow = 8

                    // Connect.it
                    if (residenceResponse.data.residence.lora.lora_count) {
                        let counts_lora = residenceResponse.data.residence.lora
                        this.nb_connectit = counts_lora.lora_count
                        accessToShow--
                    }

                    // Accesses
                    this.accesses = residenceResponse.data.acces.list.splice(
                        0,
                        accessToShow
                    )
                    this.countAccess = residenceResponse.data.acces._count

                    switch (this.type) {
                        //  BASIC
                        case residenceType.BASIC:
                            this.keys = residenceResponse.data.cles.list
                            this.pageKeysCount =
                                residenceResponse.data.cles._pages
                            break
                        // SIMPLIFIED
                        case residenceType.SIMPLIFIED:
                            this.apartments = residenceResponse.data.apparts
                            break
                        // COMPLEX
                        case residenceType.COMPLEX:
                            this.stairwells = residenceResponse.data.cages.list
                            break
                        default:
                            break
                    }
                }

                let dataFilters = {}

                if (residenceResponse.data.cage) {
                    dataFilters = {
                        residence_nom: residenceResponse.data.residence.nom,
                        residence_simple:
                            residenceResponse.data.residence.simple,
                        residence_loc_cle:
                            residenceResponse.data.residence.loc_cle,
                        residence_loc_mob:
                            residenceResponse.data.residence.loc_mob,
                        residence_loc_nom:
                            residenceResponse.data.residence.loc_nom,
                        residence_cage: residenceResponse.data.cage,
                        residence_loc_tel:
                            residenceResponse.data.residence.loc_tel,
                        residence_liberale:
                            residenceResponse.data.residence.liberale,
                        residence_loc: residenceResponse.data.residence.loc,
                        residence_robot: residenceResponse.data.residence.cnil,
                        residence_appart: residenceResponse.data.appart ?? null,
                    }
                } else {
                    dataFilters = {
                        residence_nom: residenceResponse.data.residence.nom,
                        residence_simple:
                            residenceResponse.data.residence.simple,
                        residence_loc_cle:
                            residenceResponse.data.residence.loc_cle,
                        residence_loc_mob:
                            residenceResponse.data.residence.loc_mob,
                        residence_loc_nom:
                            residenceResponse.data.residence.loc_nom,
                        residence_loc_tel:
                            residenceResponse.data.residence.loc_tel,
                        residence_liberale:
                            residenceResponse.data.residence.liberale,
                        residence_loc: residenceResponse.data.residence.loc,
                        residence_robot: residenceResponse.data.residence.cnil,
                        residence_appart: residenceResponse.data.appart ?? null,
                    }
                }

                // Stop loading
                this.isLoading = false
                let v1Call = await httpIntratone.post(
                    "data/residence/init.php",
                    `id=${this.$route.params.id}&SID=${
                        this.$store.getters["auth/getIntratoneDev2SID"]
                    }&residenceDetails=${JSON.stringify(dataFilters)}`
                )
            } catch (error) {
                console.log(error)
            }
        },
    },
    mounted() {
        this.load()
        if (this.$route.name === "Residence") {
            this.displayTab(this.tabParameter)
            if (this.tabParameter === "subaccounts") {
                this.loadSubaccounts()
            }
        }
    },
    watch: {
        tabParameter() {
            if (this.$route.name === "Residence") {
                this.displayTab(this.tabParameter)
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/details.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (min-width: 1024px) {
    .no-mobile {
        display: none;
    }
}

@media all and (max-width: 1024px) {
    .mobile {
        display: none;
    }
}

#v1-auths-bloc :deep() {
    @import "./../../assets/scss/v1-scss/style.scss";
    @import "./../../assets/scss/v1-scss/datepicker.scss";
    @import "./../../assets/scss/v1-scss/cle.scss";
    @import "./../../assets/scss/v1-scss/bootstrap/css/bootstrap.scss";
    @import "./../../assets/scss/v1-scss/bootstrap/css/bootstrap.min.scss";
    text-align: center;
}

.v1-tab-loader {
    margin-top: 25%;
}

.tab-panel {
    width: 100%;
    height: 100%;
}
</style>
